$checkbox-width: 18px;

.CheckboxInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > .multi-checkbox-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.form-checkbox {
  position: relative;
  display: block;

  > label {
    line-height: 18px;
    color: black;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
    user-select: none;
  }

  > input[type='checkbox'] {
    position: absolute;
    display: none;

    & + label {
      position: relative;
      display: inline-block;
      padding: 10px 15px 10px ($checkbox-width + 10);
      cursor: pointer;

      // Default State
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin: (-$checkbox-width/2) 0 0;
        width: $checkbox-width;
        height: $checkbox-width;
        border: 1px solid #ddd;
      }
    }

    // Checked State
    &:checked + label {
      &:before {
        background: $primaryColor1;
      }
    }

    // Disabled State
    &:disabled {
      & + label {
        cursor: not-allowed;

        &:before {
          background: grey;
        }
      }

      // Disabled Checked
      &:checked + label {
        &:before {
          background: $primaryColor1;
        }
      }
    }
  }
}
