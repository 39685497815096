.VerifiableTag {
  margin: 0px 0px 0px 0px !important;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  padding: 4px 10px;
  width: fit-content;
  background-color: $primaryColor1;
  color: white;
  border-radius: 5px;
  line-height: 1;
}
