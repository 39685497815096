.details-container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .menu-validated {
    position: absolute;
    top: 0;
    right: 0;
    background-color: forestgreen;
    font-size: 10px;
    color: white;
    font-weight: 700;
    padding: 2px 5px;
    border-radius: 0.125rem;
    width: fit-content;
    align-self: center;
    letter-spacing: 1px;
  }

  > .header-left {
    display: flex;
    flex-direction: row;
    width: 20%;

    > .header-logo {
      position: relative;
      background: #fff;
      width: max-content;
      max-width: 200px;
      height: max-content;
      max-height: 150px;
      border: 5px #dcdee1 solid;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      > img {
        width: 100%;
      }
    }
  }

  > .header-right {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
    row-gap: 20px;

    .header-details-row {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }

    .header-buttons-row {
      display: flex;
      flex-direction: row;
      justify-content: end;
      column-gap: 20px;
    }

    .header-details-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      > div:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    > div:first-child {
      flex: 0.7;
    }
  }
}

.details-header-title {
  line-height: 16px;
  color: black;
  font-size: 0.7rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0625rem;
}

.details-header-value {
  color: $primaryColor1;
  font-size: 1rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0625rem;
  padding-left: 5px;
}
