@media all {
  .page-break {
    display: none;
  }
}

@page {
  size: A4;
  margin: 3em;
}

@media print {
  html,
  body,
  body * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    visibility: hidden;
  }

  #page,
  #page * {
    visibility: visible;
  }

  #page {
    background-color: white;
    margin: 0;
    padding: 0;
    box-shadow: none;
    position: absolute;
    left: 0;
    top: 0;
  }

  .page-break {
    margin-top: 1px;
    display: block;
    page-break-before: auto;
  }
}

.SeafarerCV {
  font-size: 16px;
  display: block;
  margin: 20px;
  min-width: 250px;
  width: -webkit-fill-available;
  hr {
    margin-block-start: 0;
  }
  .SeafarerCVContent {
    display: block;
    .category {
      display: block;
      .category-name {
        display: block;
        padding: 10px 10px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
        color: black;
        min-height: 30px;
      }
      .category-content {
        display: block;
        padding: 10px 20px;
        .SubcategoryGroup {
          display: block;
          border-bottom: 0.5px solid black;
          row-gap: 30px;
          .subcategory-group-row {
            display: block;
            border-bottom: 1px solid gray;
            height: auto;
            &:last-of-type {
              border-bottom: 0px;
            }
          }
          .cv-field {
            display: inline-block;
            vertical-align: top;
            width: 150px !important;
            padding: 5px;
            .ProfileValue {
              display: block;
              margin: 0px;
              > div {
                display: block;
                width: fit-content !important;
                color: black;
                font-size: 12px;
                min-width: 150px;
                line-height: 1.5;
                height: auto;
              }
              .form-input-label {
                display: block;
                font-weight: 700;
              }
            }
          }
        }
        .cv-row {
          display: block;
          height: auto;
        }
        .cv-field {
          display: inline-block;
          vertical-align: top;
          padding: 5px;
          max-width: 100%;
          .ProfileValue {
            display: block;
            margin: 0px;
            > div {
              display: block;
              color: black;
              font-size: 12px;
              line-height: 1.5;
              height: auto;
              max-width: 100%;
            }
            .form-input-label {
              display: block;
              font-weight: 700;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

.foot-note {
  font-size: 14px;
  border-top: 0.5px solid black;
  padding-top: 15px;
}

@import '../components/scss/SeafarerCVHeader.scss';
