//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
  .action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: $gray-700;
    }
  }
  & > :not(:first-child) {
    border: inherit;
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }

  td {
    padding: 0.35rem 0.95rem;
    background-color: rgba(255, 255, 255, 0.9);
  }
}

// Tables fluid
.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}
