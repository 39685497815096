.MultiSelectInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > .multi-select {
    display: flex;
    width: 100%;
    color: $primaryColor1;
    font-weight: 400;
    font-size: 0.875rem;

    --rmsc-main: $primaryColor1;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ddd;
    --rmsc-gray: $primaryColor1;
    --rmsc-bg: #fff;
    --rmsc-p: 5px; /* Spacing */
    --rmsc-radius: 0px; /* Radius */
    --rmsc-h: 40px; /* Height */

    > div {
      width: 100%;
    }
  }
}
