.candidate-quickView-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  .candidate-logo {
    border-radius: 5%;
    height: 125px;
    width: 125px;
    border: 0px #dcdee1 solid;
    overflow: hidden;
    background: #dcdee1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .candidate-header-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;

    @media (min-width: 1200px) {
      flex-direction: row;
      text-align: left;
      align-items: center;
      justify-content: start;
    }

    > .candidate-header-center {
      margin-top: 20px;

      @media (min-width: 1200px) {
        margin-top: 0;
        margin-left: 20px;
      }

      .candidate-name {
        font-size: 32px;
        font-weight: bold;
        color: black;
      }

      .candidate-value {
        font-size: 16px;
        font-weight: 500;
        color: black;
      }
    }
  }

  .candidate-header-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0;
      flex-basis: 40%;
      max-width: 400px;
    }

    .vstack {
      align-items: center;
      justify-content: center;

      @media (min-width: 1200px) {
        align-items: flex-end;
      }
    }
    .hstack {
      justify-content: center;

      @media (min-width: 1200px) {
        justify-content: space-evenly;
      }
    }

    i {
      font-size: 20px;
    }
    .comment-field {
      width: 92%;
    }
  }
}

.card-row {
  display: flex;
  flex-wrap: wrap;

  .card-col {
    display: flex;
    flex-grow: 1;
    padding: 10px;
  }

  .quickView-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .quickView-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
}

.copy-pointer {
  cursor: copy;
}

.pointer {
  cursor: pointer;
}
