.DateTimeInput {
  display: flex;
  flex-direction: column;

  > div {
    > div {
      > input {
        border: 0;
        border: 2px solid #e1e8ee;
        border-radius: 0.25rem;
        line-height: 36px;
        box-sizing: border-box;
        padding: 0;
        color: $primaryColor1;
        font-size: 0.875rem;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        letter-spacing: 0.0625rem;
        padding-left: 5px;
        padding-right: 5px;
        min-width: 230px;
      }
    }
  }
}
