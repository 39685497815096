.EmailInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > input {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0;
    color: $primaryColor1;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
    padding-left: 5px;
    padding-right: 5px;
    transition: border-color 0.6s linear;
  }

  input:focus {
    outline: none;
  }
}
