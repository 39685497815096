.create-interview {
  display: flex;
  flex-direction: column;
  max-width: 520px;

  > .title {
    font-size: 20px;
    color: #5e6977;
    font-weight: 500;
    margin-bottom: 20px;
  }

  > .description {
    font-size: 14px;
    margin-bottom: 10px;
    margin-bottom: 30px;
  }

  > .add-date-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    > div {
      flex: 1 1 0;
    }
    input {
      width: 100%;
    }
  }

  > .interview-time-table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: start;
    padding: 20px;
    min-width: 250px;
    height: 150px;
    min-height: 150px;
    border: 1px solid #e6ecf0;
    overflow: auto;
    row-gap: 10px;
    margin-bottom: 20px;

    > .no-interview-times {
      font-size: 14px;
      color: #5e6977;
      font-weight: 500;
    }

    > .interview-time-slot {
      display: flex;
      flex-direction: row;
      height: 40px;
      > .cancel-time-icon {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: -5px;
        border-radius: 0 0.25rem 0.25rem 0;
        > img {
          height: 15px;
        }
      }
      > .value {
        border: 1px solid #b4b7b9;
        border-radius: 0.25rem;
        font-size: 12px;
        padding: 10px 20px;
        min-width: 145px;
      }
    }
  }

  > .job-card-buttons {
    display: flex;
    flex-direction: row;
    width: max-content;
    align-self: center;
    column-gap: 10px;

    > .third {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      width: 100%;
    }

    > .fourth {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  > .form-error {
    color: $primaryColor2;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    font-style: normal;
  }
}
