.SeafarerCandidateProfile {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  grid-auto-rows: max-content;
  margin: 20px;
  max-width: $max-width-layout;
  min-width: 250px;
  width: -webkit-fill-available;
}

@import '../components/scss/SeafarerHeaderCard.scss';
@import '../components/scss/CreateInterview.scss';

@import '../components/scss/HireButton.scss';
@import '../components/scss/InterviewButton.scss';
@import '../components/scss/CVButton.scss';
@import '../components//scss/InterviewRequestButton.scss';
@import '../components//scss/AddInterviewTimeButton.scss';
@import '../components/scss/VerifiableTag.scss';
