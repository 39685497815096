.FileDocumentInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > .file-input-container {
    height: 38px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    padding: 0 10px;
  }

  .file-link {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .upload-button {
    position: relative;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    input {
      opacity: 0;
      position: absolute;
      font-size: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
