//
// react-table.scss
//

.react-table {
  th,
  td {
    white-space: nowrap;
  }
  th {
    padding-left: $table-cell-padding-y;
    padding-right: 30px;
  }

  .sortable {
    position: relative;
    cursor: pointer;
    &:before {
      position: absolute;
      right: 0.5rem;
      left: auto;
      content: '\e82d';
      font-family: 'unicons';
      font-size: 1rem;
      top: 12px;
      opacity: 0.5;
    }
    &:after {
      position: absolute;
      right: 0.5em;
      left: auto;
      content: '\e829';
      font-family: 'unicons';
      top: 18px;
      opacity: 0.5;
      font-size: 1rem;
    }
    &.sorting_asc {
      &:before {
        opacity: 1;
      }
    }
    &.sorting_desc {
      &:after {
        opacity: 1;
      }
    }
  }
}
