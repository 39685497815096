.SelectInput {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;

  > select:disabled {
    cursor: not-allowed;
  }
}
