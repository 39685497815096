//
// RedHatDisplay fonts
//

@font-face {
  font-family: 'RedHatDisplay';
  src: url('../../../fonts/redhatdisplay/RedHatDisplay-Light.ttf');
  src: local('RedHatDisplay Light'), local('RedHatDisplay-Light'),
    url('../../../fonts/redhatdisplay/RedHatDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RedHatDisplay';
  src: url('../../../fonts/redhatdisplay/RedHatDisplay-Regular.ttf');
  src: local('RedHatDisplay Regular'), local('RedHatDisplay-Regular'),
    url('../../../fonts/redhatdisplay/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RedHatDisplay';
  src: url('../../../fonts/redhatdisplay/RedHatDisplay-SemiBold.ttf');
  src: local('RedHatDisplay SemiBold'), local('RedHatDisplay-SemiBold'),
    url('../../../fonts/redhatdisplay/RedHatDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RedHatDisplay';
  src: url('../../../fonts/redhatdisplay/RedHatDisplay-Bold.ttf');
  src: local('RedHatDisplay Bold'), local('RedHatDisplay-Bold'),
    url('../../../fonts/redhatdisplay/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
