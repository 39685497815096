.candidate-details-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 30px;

  > .candidate-logo {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    min-width: 150px;
    border: 5px #dcdee1 solid;
    overflow: hidden;
    background: #dcdee1;
    img {
      width: 100%;
    }
  }

  > .candidate-header-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 0;
    row-gap: 10px;
    min-width: 200px;
    max-width: 900px;
    > .candidate-name {
      font-size: 32px;
      font-weight: bold;
      color: black;
    }
    > .candidate-value {
      font-size: 16px;
      font-weight: 500;
      color: black;
    }
    > .info-text {
      font-size: 14px;
      margin-top: 30px;
    }
  }

  > .candidate-header-right {
    display: flex;
    // flex-direction: column;
    justify-content: start;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 10px;
    // margin-left: auto;
    width: 450px;
    min-width: 200px;

    // @media screen and (min-width: 768px) {
    //   margin-left: 32%;
    // }
  }
}
