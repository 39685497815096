@import './assets/scss/Saas.scss';

// colors
$primaryColor1: #0d5c75;
$primaryColor2: #f92755;
$secondaryColor3: #f6f6f6;

$white: #ffffff;
$grey: #888;
$darkgrey: #444444;

// fonts
// $font-family: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, 'Arvo', Poppins,
//  'Open Sans';

// responsive breakpoints
$break-large: 1300px;

// Layout and Grid
$max-width-layout: 1600px;
$min-width-sidenav: 250px;
// Custom styles

body {
  background-color: $off-white;
  background-image: $bg-pattern-img;
  background-repeat: no-repeat;
  background-size: 30vw;
  background-position: 80vw -15vh;
}

@media (max-width: 2200px) {
  body {
    background-size: 40vw;
    background-position: 70vw -15vh;
  }
}

@media (max-width: 1200px) {
  body {
    background-size: 60vw;
    background-position: 50vw -30vh;
  }
}

@media (max-width: 1028px) {
  body {
    background-size: 60vw;
    background-position: 50vw -30vh;
  }
}

@media (max-width: 767px) {
  body {
    background-size: 80vw;
    background-position: 55vw -15vh;
  }
}

@media (max-width: 375px) {
  body {
    background-size: 80vw;
    background-position: 55vw -5vh;
  }
}

@media print {
  .noprint {
    display: none !important;
    visibility: hidden !important;
  }
  .footer {
    min-height: 60px !important;
    max-height: 60px !important;
    height: 60px !important;
  }
  .header {
    position: unset !important;
  }
  .DashboardLayout .max-width {
    max-width: unset !important;
  }
  body {
    background-color: white;
  }
}

.notification-point {
  display: inline-block;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: $danger;
}

.filters-info div:nth-of-type(1n + 9) {
  display: none;
}

// Pages
@import './pages/Profile/scss/Profile';
@import './pages/PublicProfile/scss/PublicProfile.scss';
@import './pages/SeafarerCandidateProfile/scss/SeafarerCandidateProfile.scss';
@import './pages/SeafarerQuickViewProfile/SeafarerQuickViewProfile.scss';
@import './pages/SeafarerCV/scss/SeafarerCV.scss';

// Components
@import './components/scss/index.scss';
@import './components/Form/scss/index.scss';
@import './components/Profile/scss/index.scss';
