.candidate-cv-header {
  display: block;

  > .candidate-logo {
    display: inline-block;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    min-width: 150px;
    border: 5px #dcdee1 solid;
    overflow: hidden;
    background: #dcdee1;
    img {
      display: block;
      width: 150px;
      height: 150px;
    }
  }

  > .candidate-header-center {
    display: inline-block;
    margin-left: 20px;
    min-width: 200px;
    > .candidate-name {
      display: block;
      font-size: 32px;
      font-weight: bold;
      color: black;
    }
    > .candidate-value {
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: black;
    }
    > .info-text {
      display: block;
      font-size: 14px;
      margin-top: 30px;
    }
    .print-button-container {
      display: block;
      margin-top: 10px;
      width: 100px;
      height: auto;
    }
  }
}
