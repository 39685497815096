.add-interview-time-button {
  background-color: #fff;
  color: $primaryColor1;
  border: 3px solid $primaryColor1;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 16px;
  text-transform: uppercase;
  width: fit-content;
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

.add-interview-time-button:focus {
  outline: none;
}
.add-interview-time-button:hover {
  background-color: $primaryColor1;
  color: #fff;
}
.add-interview-time-button:active {
  transform: translateY(1px);
}
.add-interview-time-button:disabled {
  background-color: $secondaryColor3;
  color: $grey;
  cursor: not-allowed;
}
.add-interview-time-button:hover:disabled {
  background-color: darken($secondaryColor3, 10%);
}
