.interview-button {
  background-color: #8ec564;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 15px 5px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0.25rem;
  border: none;
  min-width: 150px;
}

.interview-button:focus {
  outline: none;
}
.interview-button:hover {
  background-color: darken(#8ec564, 5%);
  color: #fff;
}
.interview-button:active {
  transform: translateY(1px);
}
.interview-button:disabled {
  background-color: lighten(gray, 35%);
  color: $white;
  cursor: not-allowed;
}
.interview-button:hover:disabled {
  background-color: lighten(gray, 33%);
}
