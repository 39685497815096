.form-input-label {
  line-height: 16px;
  color: black;
  font-size: 0.7rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0625rem;
}

.form-input-error {
  color: $primaryColor2;
  display: inline-block;
  font-size: 10px;
  line-height: 20px;
  height: 20px;
  font-style: normal;
  text-align: left;
}

.profile-form-field {
  margin: 0px 10px 20px 10px;
  display: flex;
  width: 100%;
  min-width: 200px;
  flex-direction: row;
  align-items: end;

  input {
    transition: border-color 0.6s linear;
    &.critical {
      border-color: $primaryColor2;
    }
  }

  .file-input-container {
    transition: border-color 0.6s linear;
    &.critical {
      border-color: $primaryColor2;
    }
  }

  .DateInput {
    transition: border-color 0.6s linear;
    &.critical {
      .MuiOutlinedInput-notchedOutline {
        border-color: $primaryColor2;
      }
    }
  }

  .form-checkbox.critical {
    > input[type='checkbox'] {
      & + label {
        &:before {
          border: 1px solid $primaryColor2;
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}
