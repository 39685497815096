.SelectOtherInput {
  display: flex;
  flex-direction: column;
  width: 100%;

  > select {
    height: 38px;
    font-size: 0.875rem;
    width: 100%;
    font-weight: 400;
    color: $primaryColor1;
    border: 1px solid #ddd;
    padding: 0px 5px;
  }

  > input {
    border: 0;
    border: 1px solid #ddd;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0;
    color: $primaryColor1;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
    padding: 0px 5px;
  }

  > input:focus {
    outline: none;
  }
}
